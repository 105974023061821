import { Row, Col } from 'react-bootstrap';
import { Form, Label, InputGroup, Input } from 'reactstrap';
import { Button } from 'reactstrap';
import React, { Component } from 'react';

import '../../../Styles/Scss/Components/Buttons/Buttons.scss';
import '../../../Styles/Scss/Components/Forms/SetPatientPassword.scss';

import axios from 'axios';

import $ from 'jquery';

class LoginForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            OneTimePasscode: '',
            UserPassword: '',
            OneTimePassFoundUserId: null,
            OneTimePassLoginErrorMsg: null,
            LoginErrorMsg: null,

            ShowPasswordForm: false
        }

        this.oneTimePasscode = this.oneTimePasscode.bind(this);
        this.submitOneTimePasscode = this.submitOneTimePasscode.bind(this);
        this.login = this.login.bind(this);
        this.handleIsSetOwnPassword = this.handleIsSetOwnPassword.bind(this);
        this.setUserPasswordAndLogin = this.setUserPasswordAndLogin.bind(this);
        this.setUserPassword = this.setUserPassword.bind(this);
    }

    setUserPasswordAndLogin() {
        axios.post("/login/submitonetimepasswordandlogin", {
            OneTimePass: this.state.OneTimePasscode,
            UserId: this.state.OneTimePassFoundUserId,
            SetPassword: this.state.UserPassword
        }, {
            withCredentials: true,
        })
            .then(response => {
                if (response.data.status == "Failed") {
                    this.setState({ LoginErrorMsg: response.data.message });
                } else {

                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    setUserPassword(event) {
        this.setState({ UserPassword: event.target.value });
    }

    handleIsSetOwnPassword() {
        if (this.state.ShowPasswordForm == false) {
            this.setState({ ShowPasswordForm: true });
        } else {
            this.setState({ ShowPasswordForm: false });
        }
    }

    oneTimePasscode(event) {
        this.setState({ OneTimePasscode: event.target.value })
    }
    Passcode(event) {
        this.setState({ Passcode: event.target.value })
    }

    componentDidUpdate() {
        //this.props.authHandler()
    }

    submitOneTimePasscode(event) {
        axios.post("/login/submitonetimepassword", {
            OneTimePass: this.state.OneTimePasscode
        }, {
            withCredentials: true,
        })
            .then(response => {
                if (response.data.status == "Error") {
                    this.setState({ OneTimePassLoginErrorMsg: response.data.message })

                } else {
                    this.setState({ OneTimePassFoundUserId: response.data.userId });
                    this.setState({ OneTimePassLoginErrorMsg: null });
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    login(event) {

        axios.post("/login/patientlogin", {
            Username: this.state.Username,
            Passcode: this.state.Passcode,
        }, {
            withCredentials: true,
        })
            .then(response => {

                if (response.data.status == 'Success') {
                    this.setState({ LoginResult: true })
                    //Updating the main app with the login status and passing the user's name so we can pass it to other components as props
                    this.props.setUserIsLoggedIn(response.data.username);
                }
                else if (response.data.status == 'Invalid') {
                    this.setState({ LoginResult: false })
                } else {
                    this.setState({ LoginError: "Something has gone wrong." })
                }
            })
            .catch(error => {
                console.log(error);
            });

    }


    render() {

        return (
            <>
                {this.state.OneTimePassFoundUserId ?
                    //We have a userId submitted, show the set password form
                    <div className="SetPatientPassword">
                        <Row className="justify-content-center">
                            <Col xs={9} sm={9} md={4}>

                                <Row className="mt-5">
                                    <Col>
                                        <h2 className="text-white text-center size-xxlarge">Congratulations<br />You're in...</h2>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col>
                                        <p className="text-white text-center size-large">Pleae now set your own memorable password</p>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col>
                                        <Form>
                                            <Input id="setUserPassword" className="text-center" type="text"
                                                onChange={this.setUserPassword} />

                                            {this.state.LoginErrorMsg ?
                                                <Row className="mt-3">
                                                    <Col>
                                                        <p className="text-center text-white">{this.state.LoginErrorMsg}</p>
                                                    </Col>
                                                </Row>
                                                : 
                                                <></>
                                                }

                                            <Row className="mt-3">
                                                <Col>
                                                    <Button onClick={this.setUserPasswordAndLogin} className="btn primary mb-3 fw-bold size-large" block>Set your new password</Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                    </div>
                    :

                    <Row className="bg-purple">
                        <Col>
                            <Row className="justify-content-center">
                                <Col xs={12} sm={12} md={6} lg={4} className="bg-lighterPurple">
                                    {this.state.ShowPasswordForm == true ?
                                        <>
                                            <p className="text-white text-center mb-0">If it's your first time -</p>
                                            <p className="text-white text-center mb-0">Enter the passcode you where sent</p>
                                            <p className="text-white text-center mb-5"><span className="clickable" onClick={this.handleIsSetOwnPassword}><u>Tap here</u></span></p>


                                            <p className="text-white text-center fw-bold">Please enter the password you set when you logged in previously</p>
                                            <Form>
                                                <InputGroup className="mb-3 mt-3 align-items-center">
                                                    <Row className="align-items-center m-0 w-100">
                                                        <Col xs={12}>
                                                            <Input id="userPassword" type="text"
                                                                onChange={this.UserPassword} />
                                                        </Col>
                                                    </Row>
                                                </InputGroup>
                                                <Row>
                                                    <Col>
                                                        <Button onClick={this.login} className="btn primary mb-3 fw-bold size-large" block>Login</Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </>
                                        :
                                        <>
                                            <h1 className="text-white text-center size-xlarge mt-3 mb-3 px-4">Welcome to your titration tool</h1>
                                            <p className="text-white text-center">Pleae enter your one time passcode provided with your invitation.</p>
                                            <Form>
                                                <InputGroup className="mb-3 mt-3 align-items-center">
                                                    <Row className="align-items-center m-0 w-100">
                                                        <Col xs={4} className="ps-0 text-center">
                                                            <Label htmlFor="oneTimePasscode" className="pe-2 text-white mb-0">One-time Passcode</Label>
                                                        </Col>
                                                        <Col xs={8} className="pe-0">
                                                            <Input id="oneTimePasscode" type="text"
                                                                onChange={this.oneTimePasscode} />
                                                        </Col>
                                                    </Row>
                                                </InputGroup>

                                                {this.state.OneTimePassLoginErrorMsg ?
                                                    <Row className="mb-3">
                                                        <Col className="text-white text-center">
                                                            {this.state.OneTimePassLoginErrorMsg}
                                                        </Col>
                                                    </Row>
                                                    :
                                                    <></>
                                                }

                                                <Row>
                                                    <Col>
                                                        <Button onClick={this.submitOneTimePasscode} className="btn primary mb-3 fw-bold size-large" block>Confirm</Button>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col>
                                                        <p className="text-white text-center">If you've logged in before and set your own password <span className="clickable" onClick={this.handleIsSetOwnPassword}><u>tap here</u></span></p>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </>
                                    }
                                </Col>
                            </Row>

                            <Row className="justify-content-center">
                                <Col xs={12} sm={12} md={6} lg={4} className="bg-lightPurple">

                                    <Row>
                                        <Col>
                                            <p className="text-white text-center">Dispensed only by prescibing physician for instruction and guidance in regard to Type-2 Diabetes</p>
                                        </Col>
                                    </Row>


                                </Col>
                            </Row>

                            <Row className="justify-content-center">
                                <Col xs={12} sm={12} md={6} lg={4}>
                                    <p className="text-white text-center size-large mt-2 mb-5">If you feel you begin to experience any unusual symptoms? Please contact your HCP immediately.</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                }
            </>
        );
    }
}

export default LoginForm;


