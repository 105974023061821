import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { NavLink } from 'react-router-dom';

import { Input, InputGroup } from 'reactstrap';

import axios from 'axios';

import "../../../Styles/Scss/Components/Forms/ActiveCaseFilters.scss";

import ToujeoLogo from '../../../ExportedSources/Toujeo_Logo.png';

import React, { Component } from 'react';


class ActiveCases extends Component {

    constructor(props) {
        super(props);

        this.state = {
            HcpCases: [],
            NoCases: false,
            SortByCase: false,
            SortByLastActive: false,
            FilterBySearch: ''
        }

        this.listHcpCases = this.listHcpCases.bind(this);

        this.sortByCase = this.sortByCase.bind(this);
        this.sortByLastActive = this.sortByLastActive.bind(this);
        this.filterBySearch = this.filterBySearch.bind(this);
    }

    filterBySearch(event) {
        this.setState({ FilterBySearch: event.target.value });
    }

    sortByCase() {
        if (this.state.SortByCase == true) {
            //already sorted
            this.setState({ SortByCase: false, SortByLastActive: false });
            var sortedCases = [].concat(this.state.HcpCases)
                .sort((a, b) => a.id < b.id ? 1 : -1);
            this.setState({ HcpCases: sortedCases });
        } else {
            this.setState({ SortByCase: true, SortByLastActive: false });
            var sortedCases = [].concat(this.state.HcpCases)
                .sort((a, b) => a.id > b.id ? 1 : -1);
            this.setState({ HcpCases: sortedCases });
        }
    }
    sortByLastActive() {
        if (this.state.SortByLastActive === true) {
            this.setState({ SortByCase: false, SortByLastActive: false });
            var sortedCases = [].concat(this.state.HcpCases)
                .sort((a, b) => a.lastTracked < b.lastTracked ? 1 : -1);
            this.setState({ HcpCases: sortedCases });
        } else {
            this.setState({ SortByLastActive: true, SortByCase: false });
            var sortedCases = [].concat(this.state.HcpCases)
                .sort((a, b) => a.lastTracked > b.lastTracked ? 1 : -1);
            this.setState({ HcpCases: sortedCases });
        }
    }

    listHcpCases(event) {
        debugger;

        axios.get("/case/getHcpCases", {
            params: {
                userId: this.props.hcpUserId
            }
        })
            .then(response => {
                //If we get results
                if (response.data.length !== 0) {
                    this.setState({ NoCases: false, HcpCases: response.data })
                } else {
                    this.setState({ NoCases: true })
                }
                //If we dont get results
            })
            .catch(error => {
                console.log(error);
            });

    }

    componentDidMount() {
        this.listHcpCases();
    }

    render() {

        return (
            //Component Content
            <>
                <Row className="mt-3 mb-3">
                    <Col>
                        <img src={ToujeoLogo} className="img-fluid mx-auto d-block" />
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col>
                        <InputGroup>
                            <Input className="form-control border-end-0 border rounded-pill ActiveCaseFilters__FilterBySearch" id="filterBySearch" type="text"
                                onChange={this.filterBySearch} placeholder="Search via Case Number" />
                            <i className="fa fa-magnifying-glass fa-xl text-purple ActiveCaseFilters__FilterBySearch__Icon"></i>
                        </InputGroup>

                    </Col>
                </Row>

                <Row className="ActiveCaseFilters">
                    <Col className="text-purple">
                        <span className="ActiveCaseFilters__Filter" onClick={this.sortByCase}>
                            {this.state.SortByCase == true ?
                                <><span>Case No.</span> <span className="ActiveCaseFilters__Arrow up"></span></>
                                :
                                <><span>Case No.</span> <span className="ActiveCaseFilters__Arrow down"></span></>
                            }

                        </span>
                    </Col>
                    <Col className="text-center text-purple">
                        <span className="ActiveCaseFilters__Filter" onClick={this.sortByLastActive}>
                            {this.state.SortByLastActive == true ?
                                <><span>Active</span> <span className="ActiveCaseFilters__Arrow up"></span></>
                                :
                                <><span>Active</span> <span className="ActiveCaseFilters__Arrow down"></span></>
                            }
                        </span>
                    </Col>
                    <Col>

                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col>
                        {this.state.NoCases === true ? <p className="text-center">You have no active cases</p> :
                            //Show cases
                            <>
                                {this.state.HcpCases.map((data) => {

                                    var hiddenClass = "";
                                    if (!data.caseNumber.toLowerCase().includes(this.state.FilterBySearch.toLowerCase())) {
                                        hiddenClass = "d-none";
                                    }
                                    let lastTrackedDateTime = new Date(data.lastTracked).getTime();
                                    let currentDate = new Date().getTime();
                                    let timeSinceLastTracked = currentDate - lastTrackedDateTime;
                                    let daysSinceLastTracked = Math.ceil(timeSinceLastTracked / (1000 * 3600 * 24));
                                    let dateUnit = "Days";
                                    let colourClass = "";
                                    if (daysSinceLastTracked < 4) {
                                        colourClass = "text-green"
                                    } else if (daysSinceLastTracked < 60) {
                                        colourClass = "text-orange";
                                    } else {
                                        colourClass = "text-red";
                                    }

                                    if (daysSinceLastTracked === 1) {
                                        dateUnit = "Day";
                                    } else if (daysSinceLastTracked > 6 && daysSinceLastTracked < 30) {
                                        //Show in weeks
                                        if (daysSinceLastTracked < 14) {
                                            dateUnit = "Week";
                                        } else {
                                            dateUnit = "Weeks"
                                        }
                                        daysSinceLastTracked = Math.floor(daysSinceLastTracked / 7);

                                    } else if (daysSinceLastTracked >= 30 && daysSinceLastTracked < 364) {
                                        //Show in months
                                        if (daysSinceLastTracked < 60) {
                                            dateUnit = "Month";
                                        } else {
                                            dateUnit = "Months";
                                        }
                                        daysSinceLastTracked = Math.floor(daysSinceLastTracked / 30);
                                    } else if (daysSinceLastTracked >= 364) {
                                        //Show in years
                                        if (daysSinceLastTracked < 728) {
                                            dateUnit = "Year";
                                        } else {
                                            dateUnit = "Years";
                                        }
                                        daysSinceLastTracked = Math.floor(daysSinceLastTracked / 364);
                                    }



                                    return (
                                        <div key={data.id} className={`${hiddenClass}`}>
                                            <hr className="bg-purple opacity-100 mt-2 mb-2" />
                                            <Row className="text-purple">
                                                <Col>
                                                    <span className="size-large"><u>{data.caseNumber}</u></span>
                                                </Col>

                                                <Col className="text-center text-center">
                                                    <span className={`size-large ${colourClass}`}>{daysSinceLastTracked} {dateUnit}</span>

                                                </Col>

                                                <Col className="text-end">
                                                    <span className="size-large"><u>View</u></span>
                                                </Col>
                                            </Row>
                                        </div>
                                    )
                                })}
                            </>
                        }
                    </Col>
                </Row>

                <div className="bg-lightPurple text-center text-white pt-2 pb-2">
                    <NavLink to="/newcase" className="text-decoration-none d-block size-large text-white">
                        <span className="d-block BodyNavCol__Link__Icon size-xlarge">
                            <i className="fa-solid fa-circle-check"></i>
                        </span>
                        <span className="d-block">
                            New Case
                        </span>
                    </NavLink>
                </div>
                <div className="bg-purple text-center text-white pt-2 pb-2">
                    <NavLink to="/archivedcases" className="text-decoration-none d-block size-large text-white">
                        <span className="d-block BodyNavCol__Link__Icon size-xlarge">
                            <i className="fa-solid fa-lock"></i>
                        </span>
                        <span className="d-block">
                            Archive
                        </span>
                    </NavLink>
                </div>
            </>
        );
    }
}

export default ActiveCases;
