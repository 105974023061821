//Import page specific styles
import '../../Styles/Scss/Components/Footer/Footer.scss';

import SanofiLogo from '../../ExportedSources/Sanofi_Logo.png';


//Import components from Bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

//React
import React, { Component } from 'react';

//Import any other components

class AppFooter extends Component {

    constructor() {
        super();

        var today = new Date(),
            month = today.toLocaleString('default', { month: 'short' }),
            year = today.getFullYear(),
            date = month + " " + year;

        this.state = {
            date: date
        };
    }

    render() {
        return (
            <Row className="mt-3">
                <Col>
                    <Row>
                        <Col className="text-center text-purple size-small">
                            <p className="mb-1">MAT-DK-2100???/?? <span className="px-2">|</span> {this.state.date}</p>
                            <p className="mb-0">The Titration guide is intended for adults with type -2 diabetes who are prescribed Toujeo&#174;.It is developed by Sanofi as an additional support to the advice of your doctor.This information does not replace the information from the package leaflet and your doctor.Always read the package leaflet before using Toujeo&#174;.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <img className="d-block img-fluid mx-auto" width={90} src={SanofiLogo} alt="Sanofi Logo" />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center bg-purple text-white size-small py-2">
                            <p className="mb-0">Adverse events should be reported.</p>
                            <p className="mb-0">Reporting forms and information can be found at <a className="text-white fw-bold" href="https://meldenbivirkning.dk/">www.meldenbivirkning.dk</a>.</p>
                            <p>By reporting side effects, you can help increase drug safety information.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center bg-grey text-white size-small py-2">
                            <p className="mb-0 py-1">
                                <a className="px-2 text-white text-decoration-none" href="">Privacy Policy</a>
                                <a className="px-2 text-white text-decoration-none" href="">Advserse Events</a>
                                <a className="px-2 text-white text-decoration-none" href="">Contact</a>
                                <a className="px-2 text-white text-decoration-none" href="">Cookie Policy</a>
                                <a className="px-2 text-white text-decoration-none" href="">Terms of Use</a>

                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}

export default AppFooter;