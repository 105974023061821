import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { NavLink } from 'react-router-dom';

import React, { Component } from 'react';

import ToujeoLogo from '../../../ExportedSources/Toujeo_Logo.png';
import { Form, InputGroup, Input, Label } from 'reactstrap';
import { Button } from 'reactstrap';

import '../../../Styles/Scss/Components/Forms/CreateCaseForm.scss';

import axios from 'axios';

import $ from 'jquery';


class NewCase extends Component {


    constructor(props) {
        super(props);

        this.state = {
            FirstName: '',
            LastName: '',
            MedicationId: 'Toujeo',
            PatientWeight: 0,
            CurrentInsulinDose: 0,
            StartingDose: 0,
            TitrationModel: 0,
            BloodGlucoseTargetMin: 0,
            BloodGlucoseTargetMax: 4.4,

        }

        this.FirstName = this.FirstName.bind(this);
        this.LastName = this.LastName.bind(this);
        this.MedicationId = this.MedicationId.bind(this);
        this.PatientWeight = this.PatientWeight.bind(this);
        this.CurrentInsulinDose = this.CurrentInsulinDose.bind(this);
        this.CurrentInsulinDoseReduceByPerc = this.CurrentInsulinDoseReduceByPerc.bind(this);
        this.TitrationModel = this.TitrationModel.bind(this);

        this.createCase = this.createCase.bind(this);

        this.toggleCase = this.toggleCase.bind(this);

        this.StepDownBloodGlucoseTarget = this.StepDownBloodGlucoseTarget.bind(this);
        this.StepUpBloodGlucoseTarget = this.StepUpBloodGlucoseTarget.bind(this);
    }

    FirstName(event) {
        this.setState({ FirstName: event.target.value })
    }
    LastName(event) {
        this.setState({ LastName: event.target.value })
    }
    MedicationId(event) {
        this.setState({ MedicationId: event.target.value })
    }
    PatientWeight(event) {
        this.setState({ PatientWeight: event.target.value })
        //Calculate StartingDose
        if (event.target.value != 0) {
            var doseUnitsCalculated = Math.round(event.target.value * 0.2);
            this.setState({ StartingDose: doseUnitsCalculated });
        } else {
            //Assume value of 0 is a non-answer
            this.setState({ PatientWeight: 0 })
            this.setState({ StartingDose: 0 })
        }
    }
    CurrentInsulinDose(event) {
        this.setState({ CurrentInsulinDose: event.target.value })
        if (event.target.value != 0) {
            var doseUnitsCalculated = event.target.value;
            this.setState({ StartingDose: doseUnitsCalculated });
        } else {
            //Assume value of 0 is a non-answer
            this.setState({ CurrentInsulinDose: '' })
            this.setState({ StartingDose: 0 })
        }
    }
    CurrentInsulinDoseReduceByPerc(event) {
        this.setState({ CurrentInsulinDose: event.target.value })
        if (event.target.value != 0) {
            //reduce the dose by 20%
            var doseUnitsCalculated = Math.round(event.target.value * 0.8);
            this.setState({ StartingDose: doseUnitsCalculated });
        } else {
            //Assume value of 0 is a non-answer
            this.setState({ CurrentInsulinDose: '' })
            this.setState({ StartingDose: 0 })
        }
    }
    TitrationModel(event) {
        //Because of hw input ranges work, the possible values from this are 3, 5, 7..
        //If 5 we assume no decision has been taken
        if (event.target.value == 3 || event.target.value == 7) {
            this.setState({ TitrationModel: event.target.value })
        }

    }

    StepDownBloodGlucoseTarget() {
        var inputEl = document.getElementById("BloodGlucoseTarget");
        inputEl.stepDown();

        if (inputEl.value == 1) {
            this.setState({ BloodGlucoseTargetMin: 0, BloodGlucoseTargetMax: 4.4 });
        } else if (inputEl.value == 2) {
            this.setState({ BloodGlucoseTargetMin: 4.5, BloodGlucoseTargetMax: 6.0 });
        } else if (inputEl.value == 3) {
            this.setState({ BloodGlucoseTargetMin: 6.1, BloodGlucoseTargetMax: 7 });
        } else if (inputEl.value == 4) {
            this.setState({ BloodGlucoseTargetMin: 7.1, BloodGlucoseTargetMax: 8.0 });
        } else if (inputEl.value == 5) {
            this.setState({ BloodGlucoseTargetMin: 8.1, BloodGlucoseTargetMax: 0 });
        }
    }
    StepUpBloodGlucoseTarget() {
        var inputEl = document.getElementById("BloodGlucoseTarget");
        if (!inputEl.value) {
            //on first load, we do not set a value for the numeric field so as a bit of a workaround, assume its 1 and now make it equal 2
            inputEl.value = 2;
        } else {
            //Otherwise just stepup() as normal ..
            inputEl.stepUp();
        }

        if (inputEl.value == 1) {
            this.setState({ BloodGlucoseTargetMin: 0, BloodGlucoseTargetMax: 4.4 });
        } else if (inputEl.value == 2) {
            this.setState({ BloodGlucoseTargetMin: 4.5, BloodGlucoseTargetMax: 6.0 });
        } else if (inputEl.value == 3) {
            this.setState({ BloodGlucoseTargetMin: 6.1, BloodGlucoseTargetMax: 7 });
        } else if (inputEl.value == 4) {
            this.setState({ BloodGlucoseTargetMin: 7.1, BloodGlucoseTargetMax: 8.0 });
        } else if (inputEl.value == 5) {
            this.setState({ BloodGlucoseTargetMin: 8.1, BloodGlucoseTargetMax: 0 });
        }
    }

    createCase(event) {
        debugger;

        axios.post("/case/createcase", {
            FirstName: this.state.FirstName,
            LastName: this.state.LastName,
            MedicationId: this.state.MedicationId,
            PatientWeight: this.state.PatientWeight,
            CurrentInsulinDose: this.state.CurrentInsulinDose,
            StartingDose: this.state.StartingDose,
            TitrationModel: this.state.TitrationModel,
            BloodGlucoseTargetMin: this.state.BloodGlucoseTargetMin,
            BloodGlucoseTargetMax: this.state.BloodGlucoseTargetMax,

        }, {
            withCredentials: true,
        })
            .then(response => {
            })
            .catch(error => {
                console.log(error);
            });

    }

    toggleCase() {
        this.setState({ PatientWeight: 0 });
        this.setState({ CurrentInsulinDose: 0 });
        this.setState({ TitrationModel: '' });
        this.setState({ StartingDose: 0 });
        this.setState({ BloodGlucoseTargetMin: 0, BloodGlucoseTargetMax: 4.4 });
    }

    render() {
        return (
            //Component Content
            <Row className="bg-white pt-3 pb-3">
                <Col>
                    <div className="bg-purple text-center text-white pt-2 pb-2">
                        <h2 className="mb-0 size-xlarge">New Case</h2>
                    </div>

                    <Form className="CreateCaseForm">
                        <Row className="mt-3 mb-3">
                            <Col className="border-end">
                                <Row className="mb-3">
                                    <Col>
                                        <Input placeholder="1st name" className="NameInput" id="firstName" type="text"
                                            onChange={this.FirstName} />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Input placeholder="last name" className="NameInput" id="lastName" type="text"
                                            onChange={this.LastName} />
                                    </Col>
                                </Row>
                            </Col>

                            <Col className="border-start">
                                <Row>
                                    <Col>
                                        <p className="text-center fw-bold text-purple mb-2">Medication</p>
                                        <img src={ToujeoLogo} className="img-fluid mx-auto d-block" />
                                        {/*<Input id="medicationId" type="text"*/}
                                        {/*    onChange={this.MedicationId} />*/}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col>
                                <Tabs onSelect={this.toggleCase} id="justify-tab-example" className="" justify>
                                    <Tab eventKey="notTreated" className="p-4" title="Not Treated with insulin before">
                                        <Row className="align-items-center">
                                            <Col xs={4} className="ps-0 text-center">
                                                <Label htmlFor="patientWeight" className="pe-2 text-white mb-0">Patient's weight:</Label>
                                            </Col>
                                            <Col xs={8} className="pe-0">
                                                <Input type="select" name="patientWeight" id="patientWeight" value={this.state.PatientWeight} selected={this.state.optionID === Input.value}
                                                    onChange={this.PatientWeight}>
                                                    <option value="0">Please select</option>
                                                    <option value="50">50-57 Kg</option>
                                                    <option value="58">58-67 Kg</option>
                                                    <option value="68">68-77 Kg</option>
                                                    <option value="78">78-87 Kg</option>
                                                    <option value="88">88-97 Kg</option>
                                                    <option value="98">98-107 Kg</option>
                                                    <option value="108">108-117 Kg</option>
                                                    <option value="118">118-127 Kg</option>
                                                    <option value="128">128-130 Kg</option>
                                                </Input>
                                            </Col>
                                        </Row>
                                        {this.state.StartingDose != 0 ?
                                            <>
                                                <Row className="align-items-center">
                                                    <Col>
                                                        <p className="text-white text-center">X 0.2 units per kg =</p>
                                                        <p className="fw-bold text-white text-center">Starting Dose Per Day of:</p>
                                                    </Col>
                                                    <Col>
                                                        <svg width="100%" viewBox="0 0 30 42">
                                                            <g>
                                                                <path fill="#A1C480" d="M15 3 Q16.5 6.8 25 18 A12.8 12.8 0 1 1 5 18 Q13.5 6.8 15 3z" />
                                                                <text x="50%" y="30" textAnchor="middle" fontSize="12" fontWeight="bold" fill="white">{this.state.StartingDose}</text>
                                                                <text x="50%" y="35" textAnchor="middle" fontSize="5" fill="white">units</text>
                                                            </g>
                                                        </svg>
                                                    </Col>

                                                    <Col>

                                                    </Col>
                                                </Row>

                                                <hr className="bg-white opacity-100" />

                                                <Row>
                                                    <Col>
                                                        <p className="text-white text-center fw-bold">Select Either 3 or 7 day Titration Model</p>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="text-center">
                                                        3 days
                                                    </Col>
                                                    <Col>
                                                        <Input type="range" onChange={this.TitrationModel} value={this.state.value} step={2} min={3} max={7} />
                                                    </Col>
                                                    <Col className="text-center">
                                                        7 days
                                                    </Col>
                                                </Row>

                                                <hr className="bg-white opacity-100" />
                                                <Row>
                                                    <Col>
                                                        <p className="fw-bold text-white text-center">Confirm Patient's Blood Glucose Target</p>
                                                    </Col>
                                                </Row>
                                                <Row className="justify-content-center">
                                                    <Col xs={6}>
                                                        <div className="TargetCircleContainer">
                                                            <div onClick={this.StepUpBloodGlucoseTarget} id="TargetCircleContainer__StepUp" className="TargetCircleContainer__StepUp"></div>
                                                            {this.state.BloodGlucoseTargetMin == 8.1 ?
                                                                <div className="TargetCircleContainer__To size-xlarge fw-bold">
                                                                    <span>{this.state.BloodGlucoseTargetMin}+</span>
                                                                </div>
                                                                :
                                                                <>
                                                                    <div className="TargetCircleContainer__Min fw-bold size-xlarge">
                                                                        {this.state.BloodGlucoseTargetMin}
                                                                    </div>
                                                                    <div className="TargetCircleContainer__To">
                                                                        to
                                                                    </div>
                                                                    <div className="TargetCircleContainer__Max fw-bold size-xlarge">
                                                                        {this.state.BloodGlucoseTargetMax}
                                                                    </div>
                                                                </>
                                                            }
                                                            <div className="TargetCircleContainer__Units">
                                                                mmol/l
                                                            </div>

                                                            <div onClick={this.StepDownBloodGlucoseTarget} id="TargetCircleContainer__StepDown" className="TargetCircleContainer__StepDown"></div>

                                                            <div className="TargetCircle outer">
                                                                <div className="TargetCircle middle">
                                                                    <div className="TargetCircle inner">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Input id="BloodGlucoseTarget" hidden={true} type="number" min={1} max={5} step={1} readOnly></Input>
                                                    </Col>
                                                </Row>

                                                <Row className="mt-3">
                                                    <Col>
                                                        <Button onClick={this.createCase} className="btn bg-white primary mb-3 fw-bold size-large" block>Create case</Button>
                                                    </Col>
                                                </Row>
                                            </>
                                            :
                                            ''
                                        }

                                    </Tab>
                                    <Tab eventKey="switchOnce" className="p-4" title="Switch from Once daily Basal insulin">
                                        <Row className="align-items-center mb-2">
                                            <Col xs={4} className="ps-0 text-center">
                                                <Label htmlFor="onceCurrentInsulinDose" className="pe-2 text-white mb-0">Current basal insulin dose</Label>
                                            </Col>
                                            <Col xs={8} className="pe-0">
                                                <Input value={this.state.CurrentInsulinDose} placeholder="Units per day" id="onceCurrentInsulinDose" type="number"
                                                    onChange={this.CurrentInsulinDose} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <p className="text-white size-normal text-center mb-0">Change Dosage is converted on 1 to 1</p>
                                            </Col>
                                        </Row>

                                        {this.state.StartingDose != 0 ?
                                            <>
                                                <Row className="align-items-center">
                                                    <Col>
                                                        <p className="text-white text-center">Starting Dose Units Per Day</p>
                                                    </Col>
                                                    <Col>
                                                        <svg width="100%" viewBox="0 0 30 42">
                                                            <g>
                                                                <path fill="#A1C480" d="M15 3 Q16.5 6.8 25 18 A12.8 12.8 0 1 1 5 18 Q13.5 6.8 15 3z" />
                                                                <text x="50%" y="30" textAnchor="middle" fontSize="12" fontWeight="bold" fill="white">{this.state.StartingDose}</text>
                                                                <text x="50%" y="35" textAnchor="middle" fontSize="5" fill="white">units</text>
                                                            </g>
                                                        </svg>
                                                    </Col>

                                                    <Col>

                                                    </Col>
                                                </Row>

                                                <hr className="bg-white opacity-100" />
                                                <Row>
                                                    <Col>
                                                        <p className="text-white text-center fw-bold">Select Either 3 or 7 day Titration Model</p>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="text-center">
                                                        3 days
                                                    </Col>
                                                    <Col>
                                                        <Input type="range" onChange={this.TitrationModel} value={this.state.value} step={2} min={3} max={7} />
                                                    </Col>
                                                    <Col className="text-center">
                                                        7 days
                                                    </Col>
                                                </Row>
                                                <hr className="bg-white opacity-100" />

                                                <Row>
                                                    <Col>
                                                        <p className="fw-bold text-white text-center">Confirm Patient's Blood Glucose Target</p>
                                                    </Col>
                                                </Row>
                                                <Row className="justify-content-center">
                                                    <Col xs={6}>
                                                        <div className="TargetCircleContainer">
                                                            <div onClick={this.StepUpBloodGlucoseTarget} id="TargetCircleContainer__StepUp" className="TargetCircleContainer__StepUp"></div>
                                                            {this.state.BloodGlucoseTargetMin == 8.1 ?
                                                                <div className="TargetCircleContainer__To size-xlarge fw-bold">
                                                                    <span>{this.state.BloodGlucoseTargetMin}+</span>
                                                                </div>
                                                                :
                                                                <>
                                                                    <div className="TargetCircleContainer__Min fw-bold size-xlarge">
                                                                        {this.state.BloodGlucoseTargetMin}
                                                                    </div>
                                                                    <div className="TargetCircleContainer__To">
                                                                        to
                                                                    </div>
                                                                    <div className="TargetCircleContainer__Max fw-bold size-xlarge">
                                                                        {this.state.BloodGlucoseTargetMax}
                                                                    </div>
                                                                </>
                                                            }
                                                            <div className="TargetCircleContainer__Units">
                                                                mmol/l
                                                            </div>

                                                            <div onClick={this.StepDownBloodGlucoseTarget} id="TargetCircleContainer__StepDown" className="TargetCircleContainer__StepDown"></div>

                                                            <div className="TargetCircle outer">
                                                                <div className="TargetCircle middle">
                                                                    <div className="TargetCircle inner">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Input id="BloodGlucoseTarget" hidden={true} type="number" min={1} max={5} step={1} readOnly></Input>
                                                    </Col>
                                                </Row>

                                                <Row className="mt-3">
                                                    <Col>
                                                        <Button onClick={this.createCase} className="btn bg-white primary mb-3 fw-bold size-large" block>Create case</Button>
                                                    </Col>
                                                </Row>
                                            </>
                                            :
                                            ''
                                        }
                                    </Tab>
                                    <Tab eventKey="switchTwice" className="p-4" title="Switch from Twice daily Basal insulin">
                                        <Row className="align-items-center mb-2">
                                            <Col xs={4} className="ps-0 text-center">
                                                <Label htmlFor="twiceCurrentInsulinDose" className="pe-2 text-white mb-0">Current basal insulin dose</Label>
                                            </Col>
                                            <Col xs={8} className="pe-0">
                                                <Input value={this.state.CurrentInsulinDose} placeholder="Units per day" id="twiceCurrentInsulinDose" type="number"
                                                    onChange={this.CurrentInsulinDoseReduceByPerc} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <p className="text-white size-normal text-center mb-0">Reduce previous twice daily Dosage by 20%</p>
                                            </Col>
                                        </Row>

                                        {this.state.StartingDose != 0 ?
                                            <>
                                                <Row className="align-items-center">
                                                    <Col>
                                                        <p className="text-white text-center">Starting Dose Units Per Day</p>
                                                    </Col>
                                                    <Col>
                                                        <svg width="100%" viewBox="0 0 30 42">
                                                            <g>
                                                                <path fill="#A1C480" d="M15 3 Q16.5 6.8 25 18 A12.8 12.8 0 1 1 5 18 Q13.5 6.8 15 3z" />
                                                                <text x="50%" y="30" textAnchor="middle" fontSize="12" fontWeight="bold" fill="white">{this.state.StartingDose}</text>
                                                                <text x="50%" y="35" textAnchor="middle" fontSize="5" fill="white">units</text>
                                                            </g>
                                                        </svg>
                                                    </Col>

                                                    <Col>

                                                    </Col>
                                                </Row>

                                                <hr className="bg-white opacity-100" />

                                                <Row>
                                                    <Col>
                                                        <p className="text-white text-center fw-bold">Select Either 3 or 7 day Titration Model</p>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="text-center">
                                                        3 days
                                                    </Col>
                                                    <Col>
                                                        <Input type="range" onChange={this.TitrationModel} value={this.state.value} step={2} min={3} max={7} />
                                                    </Col>
                                                    <Col className="text-center">
                                                        7 days
                                                    </Col>
                                                </Row>

                                                <hr className="bg-white opacity-100" />

                                                <Row>
                                                    <Col>
                                                        <p className="fw-bold text-white text-center">Confirm Patient's Blood Glucose Target</p>
                                                    </Col>
                                                </Row>
                                                <Row className="justify-content-center">
                                                    <Col xs={6}>
                                                        <div className="TargetCircleContainer">
                                                            <div onClick={this.StepUpBloodGlucoseTarget} id="TargetCircleContainer__StepUp" className="TargetCircleContainer__StepUp"></div>
                                                            {this.state.BloodGlucoseTargetMin == 8.1 ?
                                                                <div className="TargetCircleContainer__To size-xlarge fw-bold">
                                                                    <span>{this.state.BloodGlucoseTargetMin}+</span>
                                                                </div>
                                                                :
                                                                <>
                                                                    <div className="TargetCircleContainer__Min fw-bold size-xlarge">
                                                                        {this.state.BloodGlucoseTargetMin}
                                                                    </div>
                                                                    <div className="TargetCircleContainer__To">
                                                                        to
                                                                    </div>
                                                                    <div className="TargetCircleContainer__Max fw-bold size-xlarge">
                                                                        {this.state.BloodGlucoseTargetMax}
                                                                    </div>
                                                                </>
                                                            }
                                                            <div className="TargetCircleContainer__Units">
                                                                mmol/l
                                                            </div>

                                                            <div onClick={this.StepDownBloodGlucoseTarget} id="TargetCircleContainer__StepDown" className="TargetCircleContainer__StepDown"></div>

                                                            <div className="TargetCircle outer">
                                                                <div className="TargetCircle middle">
                                                                    <div className="TargetCircle inner">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Input id="BloodGlucoseTarget" hidden={true} type="number" min={1} max={5} step={1} readOnly></Input>
                                                    </Col>
                                                </Row>

                                                <Row className="mt-3">
                                                    <Col>
                                                        <Button onClick={this.createCase} className="btn bg-white primary mb-3 fw-bold size-large" block>Create case</Button>
                                                    </Col>
                                                </Row>
                                            </>
                                            :
                                            ''
                                        }
                                    </Tab>
                                </Tabs>
                            </Col>
                        </Row>
                    </Form>


                    <div className="bg-lightPurple text-center text-white pt-2 pb-2">
                        <NavLink to="/" className="text-decoration-none size-large d-block text-white">
                            <span className="d-block BodyNavCol__Link__Icon size-xlarge">
                                <i className="fa-solid fa-folder"></i>
                            </span>
                            <span className="d-block">
                                Active Cases
                            </span>
                        </NavLink>
                    </div>
                    <div className="bg-purple text-center text-white pt-2 pb-2">
                        <NavLink to="/archivedcases" className="text-decoration-none d-block size-large text-white">
                            <span className="d-block BodyNavCol__Link__Icon size-xlarge">
                                <i className="fa-solid fa-lock"></i>
                            </span>
                            <span className="d-block">
                                Archive
                            </span>
                        </NavLink>
                    </div>

                </Col>
            </Row>
        );
    }
}

export default NewCase;
