

import React, { Component } from 'react';

import './Styles/Scss/App.scss';

import AppHeader from './Components/Header/AppHeader';
import AppFooter from './Components/Footer/AppFooter';

import Container from 'react-bootstrap/Container';

import axios from 'axios';
import AppBody from './Components/Body/AppBody';
import LoginForm from './Components/Body/Login/LoginForm';


axios.defaults.withCredentials = true;


//const token = Cookies.get("token");
//function getToken() {
//    if (token) {
//        const decodedToken = jwtDecode(token);
//        if (decodedToken.id) {
//            return token;
//        }
//    }
//}

//console.log(getToken());

class App extends Component {

    constructor(props) {
        super(props);


        this.state = {
            UserId: null,
            Username: null,
            UserIsLoggedIn: null,
            LoginFail: false,
            LoginSuccess: false
        }

    }

    setUserIsLoggedIn(username) {
        this.setState({ UserIsLoggedIn: true });
        this.setState({ Username: username });
    }

    authLogin() {
        axios.get("/auth/authlogin", {
            withCredentials: true,
        })
            .then(response => {
                if (response.data.id != null && response.data.username != null) {
                    this.setState({
                        UserId: response.data.id,
                        Username: response.data.username,
                        UserIsLoggedIn: true
                    });
                }

            })
            .catch(error => {
                console.log(error);
            });
    }

    componentDidMount() {
        this.authLogin();
    }
    

    render() {
        return (
            <Container fluid>
                <AppHeader loginStatus={this.state.UserIsLoggedIn} username={this.state.Username} />

                {this.state.UserIsLoggedIn == true ?
                    <AppBody hcpUserId={this.state.UserId} />
                    :
                    <LoginForm setUserIsLoggedIn={this.setUserIsLoggedIn.bind(this)} userIsLoggedIn={this.state.UserIsLoggedIn} />
                }
                <AppFooter />   
            </Container>
        );
    }
}

export default App;
