import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../Styles/Scss/Components/Header/Header.scss';

import SanofiLogo from '../../ExportedSources/Sanofi_Logo.png';
import ToujeoLogo from '../../ExportedSources/Toujeo_Logo.png';
import React, { Component } from 'react';


class AppHeader extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <header className="AppHeader pt-2 pb-2">
                {this.props.loginStatus == true ?
                    <Row className="align-items-center">
                        <Col>
                            <img className="d-block img-fluid me-auto" src={SanofiLogo} alt="Sanofi Logo" />
                        </Col>
                        <Col className="text-center">
                            <i className="fa-solid fa-user-doctor text-purple fa-2xl"></i>
                            <h1 className="fw-bold text-purple size-large">{this.props.username}</h1>
                        </Col>
                        <Col className="text-end">

                            <div className="MenuIcon d-inline-block">
                                <span className="MenuIcon__Burger d-block"></span>
                                <span className="MenuIcon__Burger d-block ms-auto"></span>
                                <span className="MenuIcon__Burger d-block ms-auto"></span>
                            </div>

                        </Col>
                    </Row>
                    :
                    <Row className="align-items-center">
                        <Col>
                            <img className="d-block img-fluid me-auto" src={SanofiLogo} alt="Sanofi Logo" />
                        </Col>
                        <Col>
                            <img className="d-block img-fluid mx-auto" src={ToujeoLogo} alt="Toujeo Logo" />
                        </Col>
                        <Col>
                        </Col>
                    </Row>
                }
            </header>
        );
    }
}

export default AppHeader;
