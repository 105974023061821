import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { NavLink } from 'react-router-dom';

import '../../Styles/Scss/Components/Body/BodyNav.scss';

import React, { Component } from 'react';



class BodyNav extends Component {
    render() {

        return (
            //Component Content
            <Row className="align-items-center text-center">
                <Col className="BodyNavCol p-0">
                    <NavLink to="newcase" className="text-decoration-none d-block size-large BodyNavCol__Link">
                        <span className="d-block BodyNavCol__Link__Icon size-xlarge">
                            <i className="fa-solid fa-circle-check"></i>
                        </span>
                        <span className="d-block">
                            New Case
                        </span>
                    </NavLink>
                </Col>

                <Col className="BodyNavCol p-0">
                    <NavLink to="" className="text-decoration-none size-large d-block BodyNavCol__Link">
                        <span className="d-block BodyNavCol__Link__Icon size-xlarge">
                            <i className="fa-solid fa-folder"></i>
                        </span>
                        <span className="d-block">
                            Active Cases
                        </span>
                    </NavLink>
                </Col>

                <Col className="BodyNavCol p-0">
                    <NavLink to="archivedcases" className="text-decoration-none d-block size-large BodyNavCol__Link">
                        <span className="d-block BodyNavCol__Link__Icon size-xlarge">
                            <i className="fa-solid fa-lock"></i>
                        </span>
                        <span className="d-block">
                            Archive
                        </span>
                    </NavLink>
                </Col>

            </Row>

        );
    }
}

export default BodyNav;
