import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { MemoryRouter, Route, Routes, NavLink } from 'react-router-dom';

import React, { Component } from 'react';

import BodyNav from './BodyNav';

import NewCase from './Case/NewCase';
import ActiveCases from './Case/ActiveCases';
import ArchivedCases from './Case/ArchivedCases';


class AppBody extends Component {
    render() {

        return (
            //Component Content
            <Container>
                <Row className="justify-content-center">
                    <Col xs={12} sm={12} md={6} lg={4}>
                        <Row>
                            <Col>
                                <MemoryRouter>
                                    <BodyNav />
                                    <Routes>
                                        <Route path='/' element={<ActiveCases hcpUserId={this.props.hcpUserId} />} />
                                        <Route path='/newcase' element={<NewCase hcpUserId={this.props.hcpUserId} />} />
                                        <Route path='/archivedcases' element={<ArchivedCases hcpUserId={this.props.hcpUserId} />} />
                                    </Routes>
                                </MemoryRouter>
                            </Col>
                        </Row>
                        </Col>
                    </Row>
            </Container>
            
        );
    }
}

export default AppBody;
